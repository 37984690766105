<template>
  <div>
    <v-table
      ref="table"
      path="/cars/services"
      :filter="tableFilter"
      :fields="tableFields"
      sort="-date"
      @create="createHandler"
    >
      <template #cell(serial)="data">
        <v-table-serial-column
          :data="data"
        />
      </template>
      <template #cell(operations)="{ item }">
        <div
          v-for="(o, idx) in item.operations"
          :key="idx"
        >{{ o }}</div>
      </template>
      <template #cell(actions)="data">
        <v-table-actions-column
          :view="false"
          @delete="deleteHandler(data.item)"
          @edit="editHandler(data.item)"
        />
      </template>
    </v-table>
    <b-modal
      ref="form-modal"
      :title="`${formData.id ? 'Редактирование' : 'Добавление'} обслуживания ТС`"
      @ok.prevent="saveFormData"
    >
      <b-form-group label="Дата">
        <b-form-datepicker
          v-model="formData.date"
        />
      </b-form-group>
      <b-form-group label="Пробег">
        <b-form-input
          v-model="formData.mileage"
        />
      </b-form-group>
      <b-form-group label="Стоимость">
        <b-form-input
          v-model="formData.price"
          type="number"
        />
      </b-form-group>
      <div class="operations">
        <label>Работы</label>
        <car-service-operations-field
          v-model="formData.operations"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormDatepicker, BFormGroup, BFormInput, BModal,
} from 'bootstrap-vue'
import VTable, { VTableActionsColumn, VTableSerialColumn } from '@/views/components/vtable'
import { dateFormat } from '@/libs/filters'
import CarServiceOperationsField from './CarServiceOperationsField.vue'

const emptyFormData = {
  id: null,
  mileage: null,
  price: null,
  date: null,
  operations: [''],
}

export default {
  components: {
    CarServiceOperationsField,
    VTable,
    VTableActionsColumn,
    VTableSerialColumn,
    BModal,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
  },
  props: {
    car_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dateStart: null,
      formData: { ...emptyFormData },
      tableFields: [
        {
          key: 'serial',
          label: '#',
        },
        {
          key: 'date',
          label: 'Дата',
          sortable: true,
        },
        {
          key: 'mileage',
          label: 'Пробег',
        },
        {
          key: 'operations',
          label: 'Работы',
        },
        {
          key: 'price',
          label: 'Стоимость',
          sortable: true,
        },
        {
          key: 'created_by',
          label: 'Автор',
          formatter(creator, _, item) {
            return `${creator} (${dateFormat(item.created_at)})`
          },
        },
        {
          key: 'actions',
          label: '',
        },
      ],
    }
  },
  computed: {
    tableFilter() {
      return {
        car_id: this.car_id,
      }
    },
    lastRow() {
      return this.formData.operations[this.formData.operations.length - 1]
    },
  },
  watch: {
    lastRow(v) {
      if (v.length >= 3) {
        this.formData.operations.push('')
        this.$forceUpdate()
      }
    },
  },
  methods: {
    createHandler() {
      this.formData = { ...emptyFormData }
      this.$refs['form-modal'].show()
    },
    editHandler(obj) {
      this.formData = { ...obj }
      this.$refs['form-modal'].show()
    },
    deleteHandler(obj) {
      this.$axios.delete(`/cars/services/${obj.id}`)
        .then(() => this.$refs.table.refresh())
    },
    saveFormData() {
      this.$axios[this.formData.id ? 'put' : 'post'](`/cars/services${this.formData.id ? `/${this.formData.id}` : ''}`,
        {
          ...this.formData,
          car_id: this.car_id,
          operations: this.formData.operations.filter(item => item.length > 3),
        })
        .then(() => {
          this.$refs.table.refresh()
          this.$refs['form-modal'].hide()
        })
    },
  },
}
</script>
