<template>
  <div class="row">
    <b-card class="col-lg-6 offset-lg-3 col-sm-12">
      <vue-apex-charts
        ref="orders-chart"
        :options="orders.options"
        :series="orders.series"
      />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  data() {
    return {
      orders: {
        series: [],
        options: {
          title: { text: 'Выполнено заказов' },
          chart: {
            type: 'area',
            height: '350px',
            zoom: {
              enabled: false,
            },
          },
          xaxis: {
            type: 'category',
          },
        },
      },
    }
  },
  mounted() {
    this.$axios.get('/lk/reports/orders').then(response => {
      const data = response.data.data.map(item => ({ x: item.date.replace(' 00:00', ''), y: item.count }))
      this.$refs['orders-chart'].updateSeries([{ data, name: 'Заказов' }])
    })
  },
}
</script>
