<template>
  <div>
    <h6>{{ `${$props.value.driver.last_name} ${$props.value.driver.first_name} ${$props.value.driver.middle_name}` }}</h6>
    <h6>{{ `${$props.value.car.mark} ${$props.value.car.model} ${$props.value.car.number}` }}</h6>
    <div class="mt-2 bg-light p-1">
      <div
        class="row"
      >
        <b-form-group
          v-if="action === 'open' || action === 'edit'"
          class="col"
          label="Начальный километраж"
        >
          <b-form-input
            :value="value.mileage_start"
            type="number"
            @input="updatedValueHandler('mileage_start', $event)"
          />
        </b-form-group>
        <b-form-group
          v-if="action === 'close' || action === 'edit'"
          class="col"
          label="Конечный километраж"
        >
          <b-form-input
            :value="value.mileage_end"
            type="number"
            @input="updatedValueHandler('mileage_end', $event)"
          />
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group
          v-if="action === 'edit' || action === 'open'"
          class="col"
          label="Время выдачи"
        >
          <b-form-input
            type="time"
            :value="value.date_start_time"
            @input="updatedValueHandler('date_start_time', $event)"
          />
        </b-form-group>
        <b-form-group
          v-if="action === 'edit' || action === 'close'"
          class="col"
          label="Время сдачи"
        >
          <b-form-input
            type="time"
            :value="value.date_end_time"
            @input="updatedValueHandler('date_end_time', $event)"
          />
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group
          v-if="action !== 'open'"
          class="col-12"
          label="Комментарий"
        >
          <b-form-textarea
            :value="value.comment"
            @input="updatedValueHandler('comment', $event)"
          >
          </b-form-textarea>
        </b-form-group>
        <b-form-group
          class="col-12"
          label="Статус"
        >
          <b-form-select
            :options="[
              {value: null, text: 'Выберите'},
              {value: 'opened', text: 'Открыт'},
              {value: 'closed', text: 'Закрыт'},
            ]"
            :value="value.status"
          ></b-form-select>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BFormTextarea, BFormSelect,
} from 'bootstrap-vue'

const emptyAct = {
  id: null,
  plan_id: null,
  car_id: null,
  car_group_id: null,
  driver_id: null,
  mileage_start: 0,
  mileage_end: null,
  act_date: null,
  act_time_start: null,
  act_time_end: null,
  equipped_start: null,
  equipped_end: null,
  comment: null,
}

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
  },
  props: {
    value: {
      type: Object,
      default() {
        return { ...emptyAct }
      },
    },
    action: {
      type: String,
      default: 'open',
    },
  },
  data() {
    return {
      formData: {},
    }
  },
  mounted() {
    this.formDataSetter()
  },
  methods: {
    formDataSetter() {
      this.formData = { ...emptyAct, ...this.value }
    },
    updatedValueHandler(key, value) {
      if (this.timeOut) {
        clearTimeout(this.timeOut)
      }
      this.timeOut = setTimeout(() => {
        const data = { ...this.value }
        data[key] = value
        this.$emit('input', data)
      }, 300)
    },
  },
}
</script>

<style scoped>

</style>
