<template>
  <div>
    <v-table
      ref="debt-table"
      path="/drivers/debts/car?populate=car,repaid"
      :fields="tableFields"
      :filter="tableFilter"
      @create="$refs['debt-modal'].show()"
    >
      <template #cell(serial)="data">
        <v-table-serial-column :data="data" />
      </template>
      <template #cell(actions)="{ item }">
        <v-table-actions-column
          @edit="onEditDebtHandler(item)"
          @delete="onDeleteDebtHandler(item)"
          @view="onViewDebtHandler(item)"
        >
          <template #beforeButtons>
            <span
              class="cursor-pointer text-primary mr-1"
              @click="onCreateRepaidHandler(item)"
            >
              <feather-icon
                icon="CreditCardIcon"
                size="18"
              />
            </span>
          </template>
        </v-table-actions-column>
      </template>
    </v-table>
    <b-modal
      ref="debt-modal-view"
      title="Задолженность"
      hide-footer
      @hidden="debtView=null"
    >
      <data-view
        :data="debtViewData"
        :except="['entity', 'entity_id', 'repaid']"
      />
      <h5 class="text-center pt-1 pb-1 bg-primary text-white">
        Гашения
      </h5>
      <div
        v-if="debtView && debtView.repaid"
        class="table-scrollable"
        style="max-height: 250px"
      >
        <table class="table table-sm">
          <tr>
            <th>Дата</th>
            <th>Сумма</th>
            <th>Комментарий</th>
          </tr>
          <tbody>
            <tr
              v-for="repaid in debtView.repaid"
              :key="repaid.id"
            >
              <td>{{ repaid.date }}</td>
              <td>{{ repaid.amount }}</td>
              <td>{{ repaid.description }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
    <b-modal
      ref="debt-modal"
      title="Добавить задолженность"
      @hidden="onHiddenDEbtModalHandler"
      @ok.prevent="onOkDebtModalHandler"
    >
      <b-form-group
        label="Сумма задолженности"
      >
        <b-form-input
          v-model="debtForm.amount"
          type="number"
        />
      </b-form-group>
      <b-form-group
        label="Автомобиль"
        class="autocomplete-field"
      >
        <vue-autosuggest
          :value="carSelectedDisplay"
          :suggestions="cars"
          :input-props="{
            placeholder: 'Автомобиль',
            class: 'form-control',
          }"
          :get-suggestion-value="displayCar"
          @input="onCarInputHandler"
          @selected="onCarSelectedHandler"
          component-attr-class-autosuggest-results-container="autocomplete-field-dropdown"
        >
          <template v-slot="{suggestion}">
            <span>
              {{ suggestion.item.mark }}
              {{ suggestion.item.model }}
              {{ suggestion.item.number }}
            </span>
          </template>
        </vue-autosuggest>
      </b-form-group>
      <b-form-group
        label="Комментарий"
      >
        <b-form-textarea
          v-model="debtForm.description"
        />
      </b-form-group>
    </b-modal>
    <b-modal
      ref="repaid-modal"
      title="Погасить задолженность"
      @ok.prevent="onOkRepaidHandler"
    >
      <b-form-group
        label="Сумма"
      >
        <b-form-input
          v-model="repaidForm.amount"
        />
      </b-form-group>
      <b-form-group
        label="Погасил"
      >
        <b-form-select
          v-model="repaidForm.source_of_repaid"
          :options="[
            {value: 'DRIVER', text: 'Водитель'},
            {value: 'OTHER_DRIVER', text: 'Другой водитель'},
            {value: 'INSURANCE', text: 'Страховая'},
          ]"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        label="Комментарий"
      >
        <b-form-textarea
          v-model="repaidForm.description"
        />
      </b-form-group>
      <b-form-checkbox
        v-model="repaidForm.skip_operation_create"
        :value="true"
        :unchecked-value="false"
      >
        Не создавать оплату на списание
      </b-form-checkbox>
    </b-modal>
  </div>
</template>

<script>
import VTable, {
  VTableSerialColumn,
  VTableActionsColumn,
} from '@/views/components/vtable'
import {
  BModal, BFormInput, BFormSelect, BFormGroup, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import moment from 'moment'
import DataView from '@/views/components/DataView.vue'
import { VueAutosuggest } from 'vue-autosuggest'

const DEBT_VIEW_LABELS = {
  car: 'Автомобиль',
  amount: 'Сумма задолженности',
  amount_repaid: 'Погашено',
  is_repaid: 'Погашено полностью',
  description: 'Комментарий',
  created_by: 'Добавил',
}

export default {
  components: {
    DataView,
    BModal,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BFormCheckbox,
    VTable,
    VTableSerialColumn,
    VTableActionsColumn,
    VueAutosuggest,
  },
  props: {
    driverId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      debtView: null,
      debtForm: {
        id: null,
        amount: null,
        entity_id: null,
        description: null,
      },
      repaidForm: {
        amount: null,
        driver_debt_id: null,
        description: null,
        source_of_repaid: null,
        skip_operation_create: null,
      },
      cars: [],
      carSelected: null,
      tableFields: [
        {
          key: 'serial',
          label: '#',
        },
        {
          key: 'date',
          label: 'Дата',
          formatter(date) {
            return moment(date).format('DD.MM.YYYY')
          },
        },
        {
          key: 'car',
          label: 'Автомобиль',
          formatter(car) {
            if (car) {
              return `${car.number}`
            }
            return '-'
          },
        },
        {
          key: 'amount',
          label: 'З/П/И',
          formatter(amount, _, item) {
            return `${amount}/${item.amount_repaid}/${amount - item.amount_repaid}`
          },
        },
        {
          key: 'created_by',
          label: 'Добавил',
        },
        /*
        {
          key: 'description',
          label: 'Комментарий',
        },
        */
        {
          key: 'actions',
          label: '',
        },
      ],
    }
  },
  computed: {
    tableFilter() {
      return { driver_id: this.driverId }
    },
    debtViewData() {
      if (this.debtView === null) {
        return []
      }
      return Object.keys(DEBT_VIEW_LABELS).map(key => ({
        key,
        label: DEBT_VIEW_LABELS[key],
        value: key === 'car' ? this.debtView[key].number : this.debtView[key],
      }))
    },
    carSelectedDisplay() {
      if (this.carSelected === null) return ''
      return `${this.carSelected.mark} ${this.carSelected.model} ${this.carSelected.number}`
    },
  },
  methods: {
    fetchCars(search) {
      this.cars = []
      this.$axios.get('/cars', { params: { search } }).then(response => {
        this.cars = [response.data]
        /*
        response.data.data.forEach(car => {
          this.cars.push({
            value: car.id,
            label: `${car.mark} ${car.model} ${car.number}`,
          })
        })
         */
      })
    },
    onOkDebtModalHandler() {
      const data = {
        ...this.debtForm,
        driver_id: this.driverId,
        entity_id: this.carSelected.id,
      }
      this.$axios[this.debtForm.id ? 'put' : 'post'](
        `/drivers/debts/car${this.debtForm.id ? `/${this.debtForm.id}` : ''}`, data,
      ).then(() => {
        this.$refs['debt-table'].refresh()
        this.$refs['debt-modal'].hide()
      })
    },
    onHiddenDEbtModalHandler() {
      this.debtForm.amount = null
      this.debtForm.id = null
      this.debtForm.entity_id = null
      this.debtForm.description = null
      this.carSelected = null
    },
    onViewDebtHandler(item) {
      this.debtView = { ...item }
      this.$refs['debt-modal-view'].show()
    },
    onEditDebtHandler(item) {
      this.debtForm.id = item.id
      this.debtForm.description = item.description
      this.debtForm.amount = item.amount
      this.debtForm.entity_id = item.entity_id
      this.$refs['debt-modal'].show()
    },
    onDeleteDebtHandler(item) {
      this.$axios.delete(`/drivers/debts/car/${item.id}`).then(() => {
        this.$refs['debt-table'].refresh()
      })
    },
    onOkRepaidHandler() {
      this.$axios.post('/drivers/repaid', this.repaidForm).then(() => {
        this.$refs['repaid-modal'].hide()
        this.$refs['debt-table'].refresh()
      })
    },
    onCreateRepaidHandler(debt) {
      this.repaidForm.driver_debt_id = debt.id
      // this.repaidForm.amount = debt.amount - debt.amount_repaid
      this.repaidForm.source_of_repaid = 'DRIVER'
      this.repaidForm.description = null
      this.repaidForm.skip_operation_create = false
      this.$refs['repaid-modal'].show()
    },
    onCarInputHandler(value) {
      this.fetchCars(value)
    },
    onCarSelectedHandler({ item }) {
      this.carSelected = item
    },
    displayCar({ item }) {
      return `${item.mark} ${item.model} ${item.number}`
    },
  },
}
</script>
